
export const config = {
  exp: {
    CREDENTIALS: {
      email: "insights@experionmea.com",
      password: "User@mea#2024"
    },
    ROUTES: {
      login: '/',
      home: '/vedhik',
      mednet: '/mednet',
      accenture: '/accenture',
      mahindra: '/mahindra',
      stanford: '/stanford',
      skyhigh: '/skyhigh',
      nissan: '/nissan',
      vineyard: '/vineyard',
      hicas: '/hicas'
    },
    MENUS: [
      { label: "Vedhik", key: "home", path: "/vedhik", reportId: "d92c879e-c3d3-4899-b23c-787446745a58", dataSetId: null },
      { label: "Mednet", key: "mednet", path: "/mednet", reportId: "564d3250-f406-4834-979f-447455140967", dataSetId: null },
      { label: "Accenture", key: "accenture", path: "/accenture", reportId: "b2d6734c-3e2a-4dcb-9e27-fbbf62c704a2", dataSetId: null },
      { label: "Mahindra", key: "mahindra", path: "/mahindra", reportId: "88a2da84-a936-43de-8356-520a772936e1", dataSetId: null },
      { label: "Stanford", key: "stanford", path: "/stanford", reportId: "c0b56bf2-931e-4d5d-a807-431faf0cd41f", dataSetId: null },
      { label: "Skyhigh", key: "skyhigh", path: "/skyhigh", reportId: "308b6afb-7d31-4495-9150-ddf54c0b9472", dataSetId: "6cd2eb8d-4bb1-4aaf-a7e8-a81fcf209192" },
      { label: "Nissan", key: "nissan", path: "/nissan", reportId: "d4c444fa-3363-4ae3-8967-6ec789567730", dataSetId: null },
      { label: "Vineyard", key: "vineyard", path: "/vineyard", reportId: "8b10abde-3e30-43c0-bcb1-aa9f5aa50038", dataSetId: "c55d08c1-0cfb-4e5c-a2e9-aae2b5401967" },
      { label: "Hicas", key: "hicas", path: "/hicas", reportId: "1ebb095a-12ee-4487-9e17-8e37a2faba9a", dataSetId: null },
    ]
  },

  lni: {
    CREDENTIALS: {
      email: "insights@learnninspire.com",
      password: "User@lni#2024"
    },
    ROUTES: {
      login: '/',
      home: '/metamorphosis',
      club2: '/lniglobal',
      club3: '/lnihealth'
    },
    MENUS: [
      { label: "Metamorphosis", key: "home", path: "/metamorphosis", reportId: "41d0b898-0fd7-4d88-bd91-0e76364b5f12", dataSetId: "1f9f5761-5db2-4113-8175-fc0bc1b66ad9" },
      { label: "LNI Global", key: "lniglobal", path: "/lniglobal", reportId: "41d0b898-0fd7-4d88-bd91-0e76364b5f12", dataSetId: "1f9f5761-5db2-4113-8175-fc0bc1b66ad9" },
      { label: "LNI Health", key: "lnihealth", path: "/lnihealth", reportId: "41d0b898-0fd7-4d88-bd91-0e76364b5f12", dataSetId: "1f9f5761-5db2-4113-8175-fc0bc1b66ad9" }
    ]
  },

  jdedu: {
    CREDENTIALS: {
      email: "insights@jdedu.com",
      password: "User@jdedu#2024"
    },
    ROUTES: {
      login: '/',
      home: '/vivekananda',
      djglobal: '/djglobal',
      hindustan: '/hindustan',
      ksr: "/ksr"
    },
    MENUS: [
      { label: "Vivekananda", key: "home", path: "/vivekananda", reportId: "6f5e3e21-0592-476d-91b4-6a894dab3d9e", dataSetId: "b8432dd2-6e42-4190-8718-5fd324452c97" },
      { label: "Hindustan", key: "hindustan", path: "/hindustan", reportId: "068abf7e-e9c7-4b91-a65b-54a2f12e1c24", dataSetId: "ac1c842c-3a49-4098-82af-ef113975e73a" },
      { label: "KSR", key: "ksr", path: "/ksr", reportId: "2abf27e7-f87a-410b-b376-3065cfde1294", dataSetId: "3917e053-2da9-440d-a5b5-18319f8009eb" }

    ]
  },
  mjade: {
    CREDENTIALS: {
      email: "insights@megajade.com",
      password: "User@megajade#2024"
    },
    ROUTES: {
      login: '/',
      home: '/megajade',
      so2: '/so2',
      so3: '/so3'
    },
    MENUS: [
      { label: "Megajade", key: "megajade", path: "/megajade", reportId: "f8bd2d55-c039-495e-9be9-8a5a918c57d9", dataSetId: "fa7be348-4896-459d-a593-81d3536fcdf5" },
      { label: "SO2", key: "so2", path: "/so2", reportId: "8d3c2e35-c2da-4827-9120-424a1a8a1f09", dataSetId: "6ba7cd36-a2cc-4928-961a-90c9f702143a" },
      { label: "SO3", key: "so3", path: "/so3", reportId: "690bb5ed-d43d-4d93-94e6-196f95ce3dcb", dataSetId: "3d1b2e3c-ce32-472b-be74-baef44ad2b06" }

    ]
  },
  maasa: {
    CREDENTIALS: {
      email: "insights@maasa-ai.com",
      password: "User@maasa#2024"
    },
    ROUTES: {
      login: '/',
      home: '/maasaacademy'
    },
    MENUS: [
      { label: "Maasa Academy", key: "maasaacademy", path: "/maasaacademy", reportId: "4b4d619f-458e-4108-b34e-5d998805786f", dataSetId: "d0eb21d1-6b6a-4d2e-9083-5c2442ec4430" }

    ]
  },
  exult: {
    CREDENTIALS: {
      email: "insights@exultglobal.com",
      password: "User@exult#2024"
    },
    ROUTES: {
      login: '/',
      home: '/customer1',
      customer2: '/customer2',
      customer3: '/customer3',
      customer4: '/customer4',
      customer5: '/customer5',
      customer6: '/customer6',
      customer7: '/customer7',
      customer8: '/customer8'
    },
    MENUS: [
      { label: "Customer 1", key: "home", path: "/customer1", reportId: "39dd7757-639a-4f49-912f-164d74eb68e7", dataSetId: "5b492343-4fdd-4b9b-9a6d-a754884a8999" },
      { label: "Customer 2", key: "customer2", path: "/customer2", reportId: "19b77f85-161c-4753-802e-ceb2fac491be", dataSetId: "bd3e5238-1d17-49fd-9e43-c5ffe31ca616" },
      { label: "Customer 3", key: "customer3", path: "/customer3", reportId: "ec52d0f6-cf17-44f3-9cce-4ecb4875bbeb", dataSetId: "2183d829-9938-475c-837f-165daed8dc6a" },
      { label: "Customer 4", key: "customer4", path: "/customer4", reportId: "a5b15d3f-17aa-4003-a42c-fe5e70c7677e", dataSetId: "d1e3506a-6a6e-42ed-810a-8ec0d586b150" },
      { label: "Customer 5", key: "customer5", path: "/customer5", reportId: "10ef150d-9fee-4230-a8a4-4b80fd6b0d1d", dataSetId: "69b40446-98b8-456c-b9b6-a4cb6e8ded24" },
      { label: "Customer 6", key: "customer6", path: "/customer6", reportId: "6985c285-a072-4275-a8a4-898fb86ec1d9", dataSetId: "db34949e-d7d2-45b9-adc6-e92440bae8ea" },
      { label: "Customer 7", key: "customer7", path: "/customer7", reportId: "4e60896e-81e3-4fd4-9542-552ab90ee86b", dataSetId: "0ff02c07-efdb-48b8-9835-ef7966f2cf2e" },
      { label: "Customer 7", key: "customer8", path: "/customer8", reportId: "31b4f54c-95b4-4b4a-826f-f58f455f2492", dataSetId: "cb9603dc-ce0b-488b-a8af-a724ce86c876" }
    ]
  },
  techvan: {
    CREDENTIALS: {
      email: "insights@techvantagesystems.com",
      password: "User@techvantage#2024"
    },
    ROUTES: {
      login: '/',

    },
    MENUS: [
      { label: "Customer 1", key: "home", path: "/customer1", reportId: "39dd7757-639a-4f49-912f-164d74eb68e7", dataSetId: "5b492343-4fdd-4b9b-9a6d-a754884a8999" },
    ]
  }
}
